/*!
  Theme: Hardcore
  Author: Chris Caller
  License: ~ MIT (or more permissive) [via base16-schemes-source]
  Maintainer: @highlightjs/core-team
  Version: 2021.09.0
*/
pre code.hljs {
  padding: 1em;
  display: block;
  overflow-x: auto;
}

code.hljs {
  padding: 3px 5px;
}

.hljs {
  color: #cdcdcd;
  background: #212121;
}

.hljs ::selection, .hljs::selection {
  color: #cdcdcd;
  background-color: #353535;
}

.hljs-comment {
  color: #4a4a4a;
}

.hljs-tag {
  color: #707070;
}

.hljs-operator, .hljs-punctuation, .hljs-subst {
  color: #cdcdcd;
}

.hljs-operator {
  opacity: .7;
}

.hljs-bullet, .hljs-deletion, .hljs-name, .hljs-selector-tag, .hljs-template-variable, .hljs-variable {
  color: #f92672;
}

.hljs-attr, .hljs-link, .hljs-literal, .hljs-number, .hljs-symbol, .hljs-variable.constant_ {
  color: #fd971f;
}

.hljs-class .hljs-title, .hljs-title, .hljs-title.class_ {
  color: #e6db74;
}

.hljs-strong {
  color: #e6db74;
  font-weight: 700;
}

.hljs-addition, .hljs-code, .hljs-string, .hljs-title.class_.inherited__ {
  color: #a6e22e;
}

.hljs-built_in, .hljs-doctag, .hljs-keyword.hljs-atrule, .hljs-quote, .hljs-regexp {
  color: #708387;
}

.hljs-attribute, .hljs-function .hljs-title, .hljs-section, .hljs-title.function_, .ruby .hljs-property {
  color: #66d9ef;
}

.diff .hljs-meta, .hljs-keyword, .hljs-template-tag, .hljs-type {
  color: #9e6ffe;
}

.hljs-emphasis {
  color: #9e6ffe;
  font-style: italic;
}

.hljs-meta, .hljs-meta .hljs-keyword, .hljs-meta .hljs-string {
  color: #e8b882;
}

.hljs-meta .hljs-keyword, .hljs-meta-keyword {
  font-weight: 700;
}
/*# sourceMappingURL=index.df04d992.css.map */
